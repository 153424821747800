.card {
  background-image: url('./images/cards-compressed.png');
  -webkit-background-size: 1600px 639px;
  background-size: 1600px 639px;
  /*800px */
  width: 113px;
  height: 158px;
  position: absolute;
  z-index: 2;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 6px;
  -webkit-transition: -webkit-transform .6s ease-out;
  transition: -webkit-transform .6s ease-out;
  transition: -ms-transform .6s ease-out;
  transition: transform .6s ease-out;
  -webkit-transform: translateX(0px) translateY(0px) rotateZ(0deg);
  -ms-transform: translateX(0px) translateY(0px) rotateZ(0deg);
  transform: translateX(0px) translateY(0px) rotateZ(0deg);
}

._A.spade {
  background-position: -1px -321px;
}

._2.spade {
  background-position: -115px -321px;
}

._3.spade {
  background-position: -229px -321px;
}

._4.spade {
  background-position: -343px -321px;
}

._5.spade {
  background-position: -457px -321px;
}

._6.spade {
  background-position: -571px -321px;
}

._7.spade {
  background-position: -685px -321px;
}

._8.spade {
  background-position: -799px -321px;
}

._9.spade {
  background-position: -912px -321px;
}

._10.spade {
  background-position: -1027px -321px;
}

._J.spade {
  background-position: -1141px -321px;
}

._Q.spade {
  background-position: -1255px -321px;
}

._K.spade {
  background-position: -1371px -321px;
}

._A.heart {
  background-position: -1px -161px;
}

._2.heart {
  background-position: -115px -161px;
}

._3.heart {
  background-position: -229px -161px;
}

._4.heart {
  background-position: -343px -161px;
}

._5.heart {
  background-position: -457px -161px;
}

._6.heart {
  background-position: -571px -161px;
}

._7.heart {
  background-position: -685px -161px;
}

._8.heart {
  background-position: -799px -161px;
}

._9.heart {
  background-position: -913px -161px;
}

._10.heart {
  background-position: -1027px -161px;
}

._J.heart {
  background-position: -1141px -161px;
}

._Q.heart {
  background-position: -1256px -161px;
}

._K.heart {
  background-position: -1371px -161px;
}

._A.diamond {
  background-position: -1px -481px;
}

._2.diamond {
  background-position: -115px -481px;
}

._3.diamond {
  background-position: -228px -481px;
}

._4.diamond {
  background-position: -343px -481px;
}

._5.diamond {
  background-position: -457px -481px;
}

._6.diamond {
  background-position: -571px -481px;
}

._7.diamond {
  background-position: -685px -481px;
}

._8.diamond {
  background-position: -799px -481px;
}

._9.diamond {
  background-position: -913px -481px;
}

._10.diamond {
  background-position: -1027px -481px;
}

._J.diamond {
  background-position: -1141px -481px;
}

._Q.diamond {
  background-position: -1256px -481px;
}

._K.diamond {
  background-position: -1371px -481px;
}

._A.club {
  background-position: -1px -1px;
}

._2.club {
  background-position: -115px -1px;
}

._3.club {
  background-position: -229px -1px;
  /* 58 */
}

._4.club {
  background-position: -343px -1px;
}

._5.club {
  background-position: -457px -1px;
}

._6.club {
  background-position: -571px -1px;
}

._7.club {
  background-position: -685px -1px;
}

._8.club {
  background-position: -799px -1px;
}

._9.club {
  background-position: -913px -1px;
}

._10.club {
  background-position: -1027px -1px;
}

._J.club {
  background-position: -1141px -1px;
}

._Q.club {
  background-position: -1255px -1px;
}

._K.club {
  background-position: -1371px -1px;
}
